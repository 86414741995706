import { DISABLEPLAYER_BUTTON, MAXIMIZE_MUSIC_PLAYER, PLAY_PAUSE, RESET_MUSIC_PLAYER_STATE, SET_MUSIC_PAYLOAD, SUFFLED_RESET_DATA } from "../../actions/musicPlayer/action-names";
import { IMusicState } from "../../actions/musicPlayer/action-types";


export const initialState: IMusicState = {
    musicPlayerPayload: null,
    playerMaxiMize: false,
    suffleResetData: null,
    playerPlayPause: true,
    disableMusicPlayerButton: false
};

export default function musicReducer(
    state: IMusicState = initialState,
    action: any
): IMusicState {
    switch (action.type) {
        case SET_MUSIC_PAYLOAD:
            return {
                ...state,
                musicPlayerPayload: action.payload,
            };
        case SUFFLED_RESET_DATA:
            return {
                ...state,
                suffleResetData: action.payload
            };
        case RESET_MUSIC_PLAYER_STATE:
            return {
                ...state,
                musicPlayerPayload: null,
                suffleResetData: null,
                playerPlayPause: true,
                disableMusicPlayerButton: false
            };
        case MAXIMIZE_MUSIC_PLAYER:
            return {
                ...state,
                playerMaxiMize: action.payload
            };
        case PLAY_PAUSE:
            return {
                ...state,
                playerPlayPause: action.payload
            };
        case DISABLEPLAYER_BUTTON:
            return {
                ...state,
                disableMusicPlayerButton: action.payload
            };
        default:
            return { ...state };
    }
}