import {  CHANGE_THEME, SHOW_SIDEBAR } from "./action-names";
import { ChangeTheme, ShowSideBar } from "./action-types";

const showSideBar = (isShow: boolean): ShowSideBar => {
  return {
    type: SHOW_SIDEBAR,
    payload: isShow,
  };
};

const changeTheme = (isChange: boolean): ChangeTheme => {
  return {
    type: CHANGE_THEME,
    payload: isChange,
  };
};

export const layoutActions = {
  showSideBar,
  changeTheme
};
