import { SUBSCRIBER_UPDATE_PROFILE } from "../../actions/subscriberProfile/action-names";
import { SubscriberUpdateProfileType } from "../../actions/subscriberProfile/action-types";

const dummyProfilePic ="https://picsum.photos/200"
const initialState: SubscriberUpdateProfileType = {
 subscriberprofile: dummyProfilePic,
};

export default function SubscriberProfileReducer(
 state: SubscriberUpdateProfileType = initialState,
 action: any
): SubscriberUpdateProfileType {
 switch (action.type) {
  case SUBSCRIBER_UPDATE_PROFILE:
   return {
    ...state,
    subscriberprofile: action.payload
   };
 
  default:
   return { ...state };
 }
}