
import { SUBSCRIBER_NOTIFICATIONS } from "./action-names";

export const subscriberAction = {
  subscriberNotification,
};

function subscriberNotification(count: number){
  return {
    type: SUBSCRIBER_NOTIFICATIONS,
    subscriberNotification: count,
  };
}

