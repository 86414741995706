import { CHANGE_THEME, SHOW_SIDEBAR } from "../../actions/layout/action-names";
import { ILayout } from "../../actions/layout/action-types";

const initialState: ILayout = {
  showSideBar: false,
  changeTheme: false,
};

export default function layoutReducer(
  state: ILayout = initialState,
  action: any
): ILayout {
  switch (action.type) {
    case SHOW_SIDEBAR:
      return {
        ...state,
        showSideBar: action.payload,
      };
    case CHANGE_THEME:
      return {
        ...state,
        changeTheme: action.payload,
      }
    default:
      return { ...state };
  }
}
