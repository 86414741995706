import { FOLLOW_ARTIST } from "../app-config";

export const messageObject = {
    something_went_wrong: "Something went wrong",
    not_login: "You are not logged in. please login",
    not_login_subscriber: "You should be logged in as a subscriber.",
    name: "Please enter your Full Name.",
    password: "Please enter password.",
    currentPassword: "Please enter current password.",
    newPassword: "Please enter new password.",
    confirmPassword: "Please confirm your Password.",
    validConfirmPassword: "Password and Confirm Password fields must be identical. Please try again.",
    passwordMinLimit: "Password can not be less than 8.",
    validpassword: "Password must have minimum 8 characters, with at least 1 upper case letter, 1 lower case letter, 1 numeric and 1 special character.",
    contactUs: 'Maximum 500 characters allowed',
    contactUsMsg: 'System administrator will get in touch with you soon.',


    mobile: "Please enter Phone Number.",
    mobileMaxLimit: "Phone Number can not be more than 15.",
    mobileMinLimit: "Phone Number can not be less than 10.",
    validMobile: "Please enter numeric value only.",

    imageSize: 'Image can not be more than 4 mb.',
    usernamePwdIncMsg: "Username or password is incorrect",
    userRegistered: "User registered sucessfully. An OTP has been sent to your registered email id.",
    loginSuccess: 'Logged in successfully',
    FB_login_INFO: `You can't login with mobile number.`,

    profilePicUpdatSuccess: "The profile pic has been updated.",
    aboutMe: "Please enter your bio.",
    email: "Please enter an Email Id.",
    validEmail: "Please enter a valid Email Id.",
    emailNotFound: "This email id is not registered with us. Please try again.",
    emailMinLimit: "Email can not be less than 6.",
    registeredEmail: "Please enter registered Email Id.",
    emailExist: "This email id is already registered. Please try another.",
    selectGender: "Please select Gender.",
    validWebsiteUrl: "Please enter a valid website url.",

    //create album.
    albumTitle: "Please enter Album Title.",
    albumStatus: "Please select Album Status.",
    validAlbumTitle: "Please enter valid Album Title. it should be alphabets only.",
    albumDescription: "Please enter Album Description.",
    albumGenre: "Please enter Genre",
    validAlbumGenre: "Please enter valid Genre. it should be alphabets only.",
    coverPicUrl: "Please select a Cover Picture.",
    selectDate: "Please select a Release Date.",
    validDate: "Invalid date format. It should be like MM/DD/YYYY",
    createdAlbumSuccess: "The Album has been saved.",
    thumbnailPicUrl: "Please select a Thumbnail Picture.",
    songuploadErr: "Please upload MP3 file.",
    songTitle: "Please enter Song Title.",
    validsongTitle: "Please enter valid Song Title. it should be alphabets only.",
    artistActicve: "Artist activted successfully",
    artistdeActicve: "Artist deactivted successfully",
    createAlbumSuccess: "The Album has been saved.",
    inactiveArtist: "Artist is currently inactive. Please contact administrator for more info.",

    // addSongSuccess: "The Song has been saved.",
    reUploadSongSuccess: "Song added in album.",
    deleteSongSuccess: "The Song has been deleted.",
    uploadSongProcess: "Currently song is uploading on server please wait.",
    failedSongUploadMsg: 'Due to some issue song is not uploaded on server. Please retry!!',


    //confirmation message
    songDelete: "Are you sure you want to delete this song?",
    songRemove: "Are you sure you want to remove this song?",
    statusInactiveToActive: "Are you sure you want to change status from Inactive to Active?",
    statusActiveToInActive: "Are you sure you want to change status from Active to Inactive?",
    songDelWithAlbum: "Are you sure you want to delete this song. If you delete this song album will also be deleted.",
    albumDelete: "Are you sure you want to delete this Album. If you delete this album then album related all songs will also be deleted.",

    otpMessage: "Please enter OTP.",
    validOtp: "Please enter valid OTP number it should be numbers only.",
    emailNotVarified: "Email not be verified. Please verify email.",

    //PlayList
    myPlayListName_error: "Please enter Playlist Name.",
    playlistDelete: "Are you sure want to delete this Playlist?",

    inactiveSong: "Song is inactivated by artist.",
    inactiveAlbum: "Album is inactivated by artist.",
    inactiveAlbumorSong: "Album or song is inactivated by artist.",
    cannotSentSongYourSelf: "You can't share song yourself.",

    //Upload image validation message
    // imageFormat_error: "Please select only Image format.",
    //imageFormat_error: "Please select JPG JPEG or PNG Image format for upload",
    imageFormat_error: "Please upload one of the following file types: .jpeg, jpg, or .png. ",
    imageMaxsize_error: "Image can not be more than 4 mb.",
    ADD_SONG_TOPLAYLIST_ERROR: "Please select playlist first",
    ADD_PLAYLIST_SUCCESS: "Song added in My Playlist successfully",
    uploadImgErr: "Please check your system date before upload image.",

    //Upload song  validation massages
    songFormatErrMsg: "Please select only MP3 up to 320kbps file format.",
    songMaxSizeErrMsg: 'Audio can not be more than 25 mb',
    uploadSongErr: "Please check your system date before upload song.",
    unableToUploadSong: "Unable to upload audio file due to file extension not recognized on device. Try from other source.",
    //songplay count success message
    ADD_SONGPLAYCOUNT_SUCCESS: "song play count added successfully",

    // songplay error
    previewSonginfo: `Please ${FOLLOW_ARTIST.toLocaleLowerCase()} the artist to listen full song.`,
    SONG_PLAY_FOLLOW_ERROR: `Can't play this songs please ${FOLLOW_ARTIST.toLocaleLowerCase()} artist first.`,
    ADD_SONG_IN_MYPLAYLIST_ERROR: `Can't add this songs in My Playlist please  ${FOLLOW_ARTIST.toLocaleLowerCase()} artist first.`,
    ADD_TO_QUEUE_ERROR: `Can't add this song to queue please  ${FOLLOW_ARTIST.toLocaleLowerCase()} artist first.`,

    //cancel subscription message
    CANCEL_SUBSCRIBER_SUBSCRIPTION_MSG: "Are you sure you want to unsubscribe to this plan? If you unsubscribe, you will not be able to subscribe to any new artists and all the artists you are currently subscribed to will be removed from your list. You will however have access to your current artist list until your subscription period has ended.",
    CANCEL_SUBSCRIPTION_SUCCESS: "Plan unsubscribed successfully.",

    //============================ Admin (Subscription plan) ===========================\\
    updateSubscriptionPlan: "Subscription plan updated successfully.",
    numberOfArtistErr: 'Please enter Number Of Artist.',

    //============================ Admin (commission slab) ===========================\\
    updateCommissionSlab: "Commission slab updated successfully.",
    commissionAmtErr: 'Please enter commission amount',
    slabErr: "Please enter slab.",
    inValidSlabData: "Invalid slab",
    slabRequired: "Please configure atleast one slab.",

    myPlayListSongPlayError: "Can't play this playList please add some songs in myplaylist.",
    selectArtistOrEmail: "Please select either Artist or Email.",
    upGradePlanConfirmMessage: "Are you sure you want to upgrade the subscription plan.",
    subscriptionPlanUnderCancellation: 'The current plan is under cancellation. You will be able to take subscription after cancellation.',
    //upgrade popup msg
    popupSubscriptionTitlemsg: 'Subscription Alert',
    popupSubscriptionSubTitleMsg: `There is no subscription to ${FOLLOW_ARTIST.toLocaleLowerCase()} artist.`,

    //cancel artist subscription msg
    cancelSubscriptionTitle: 'Cancel artist subscription confirmation',
    cancelSubscriptionDiscription: "Are you sure you want to cancel subscription. If you cancel the subscription, your data will be deleted at the end of subscription payment cycle.",


    //Inactive
    SongIsInActive: 'Song is inactive.',
    sharedSongExpiredOrInActive: "Shared song is expired or song is inactive.",
    shareSongMsg: "Please enter a message.",
    invalidShared: "You are logged in with another user or shared song does not exist.",
    notificationNotSupported: "Notification is not supported in the browser.",

    fromDateErrMsg: "From Date cannot be greater then to date.",
    todateErrMsg: "To Date cannot be less then from date."
}


