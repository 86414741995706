import { ARTIST_NOTIFICATIONS } from "../../actions/artistNotification/action-names";
import { ArtistNotificationType } from "../../actions/artistNotification/action-types";


interface NotiticationState {
  isLoading: boolean;
  countA: number;
}

const initialState: NotiticationState = {
  isLoading: false,
  countA: 0,
};

export default function notificationReducer(
  state: NotiticationState = initialState,
  action: any
): NotiticationState {
  const successAction = action as ArtistNotificationType;

  switch (action.type) {
    case ARTIST_NOTIFICATIONS:
      return {
        ...state,
        countA: successAction.artistNotification,
        isLoading: false,
      };
    default:
      return state;
  }
}