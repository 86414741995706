import { combineReducers } from "redux";
import { RESET_STORE } from "../actions/resetApp/action-names";
import layoutReducer from "./layout";
import logoutReducer from "./logout";
import musicReducer from "./musicPlayer";
import myPlayListReducer from "./myPlaylistData";
import SubscriberProfileReducer from './subscriberProfile';
import SubscriberNotificationsReducer from './subscriberNotification';
import ArtisNotificationReducer from './artistNotification';
const appReducer = combineReducers({
  Layout: layoutReducer,
  Logout: logoutReducer,
  MyPlayList: myPlayListReducer,
  MusicPlayer: musicReducer,
  subscriberProfile: SubscriberProfileReducer,
  subscriberNotification: SubscriberNotificationsReducer,
  artistNotification: ArtisNotificationReducer,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === RESET_STORE) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
