import { DISABLEPLAYER_BUTTON, MAXIMIZE_MUSIC_PLAYER, PLAY_PAUSE, RESET_MUSIC_PLAYER_STATE, SET_MUSIC_PAYLOAD, SUFFLED_RESET_DATA } from "./action-names";
import { IMusicPlayer, MusicPlayerMaxiMize, ResetMusicPlayerPayload, SetMusicPayload } from "./action-types";

const setMusicPayload = (musicPayload: IMusicPlayer): SetMusicPayload => {
    return {
        type: SET_MUSIC_PAYLOAD,
        payload: musicPayload,
    };
};

const setSuffledResetData = (musicPayload: IMusicPlayer): SetMusicPayload => {
    return {
        type: SUFFLED_RESET_DATA,
        payload: musicPayload,
    };
};

const resetMusicPlayerPayload = (): ResetMusicPlayerPayload => {
    return {
        type: RESET_MUSIC_PLAYER_STATE,
    };
};

const maxiMizeMusicPlayer = (miniMize: boolean): MusicPlayerMaxiMize => {
    return {
        type: MAXIMIZE_MUSIC_PLAYER,
        payload: miniMize,
    };
};

const playPauseMusicPlayer = (playPause: boolean): MusicPlayerMaxiMize => {
    return {
        type: PLAY_PAUSE,
        payload: playPause,
    };
};


const musicPlayerButtonDisable = (disable: boolean): MusicPlayerMaxiMize => {
    return {
        type: DISABLEPLAYER_BUTTON,
        payload: disable,
    };
};

export const musicPlayerActions = {
    setMusicPayload,
    resetMusicPlayerPayload,
    maxiMizeMusicPlayer,
    setSuffledResetData,
    playPauseMusicPlayer,
    musicPlayerButtonDisable,
};
