import { gql } from '@apollo/client';


export const BROWSERLAUNCH = gql`

mutation ( $browserDeviceToken:String!,$userId:Long!,$notificationType:Int! )
{
   browserLaunch(input:{
     browserDeviceToken:$browserDeviceToken
     userId:$userId
     notificationType:$notificationType
   }){
       unReadCount,
       status,
       successMessage,
   }
}
`;
