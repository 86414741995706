
import { ARTIST_NOTIFICATIONS } from "./action-names";

export const artistAction = {
artistNotification,
};

function artistNotification(countA: number) {
 return {
  type: ARTIST_NOTIFICATIONS,
  artistNotification: countA,
 };
}

