
/*========================================= validation through useFrom ================================= */
import moment from 'moment';
import { messageObject } from '.';
import { AdminRole } from '../app-config';
import { musicPlayerActions } from '../redux/actions/musicPlayer';
import { IUrlObj, IMusicState } from '../redux/actions/musicPlayer/action-types';
import { IPasswordState } from '../subscribers/components/register';

type ValidationObject = {
  value: RegExp,
  message: string,
}

export const validateAplhabetsOnly = (validMassage: string): ValidationObject => {
  return {
    value: /^[A-Za-z ]+$/,
    message: validMassage,
  }
}

export const validateNumberOnly = (validMassage: string): ValidationObject => {
  return {
    value: /^[0-9]+$/,
    message: validMassage,
  }
}

export const validateEmail = (validMassage: string): ValidationObject => {
  return {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: validMassage,
  }
}


export const validatePassword = (validMassage: string): ValidationObject => {
  return {
    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%&*?])[A-Za-z\d#$@!%&*?]{8,32}$/,
    message: validMassage
  }
}






/* ======================================== Manual Validation =========================================== */
/* ======================================= For Controlled Form ========================================= */

/**
 * Validate string using regex.
 * Accept aphabets and space.
 * @param value
 * @returns
 */
export const validateStateOrCity = (value: string): RegExpMatchArray | null => {
  return value.match(/^[a-zA-Z ]*$/);
}




export const validatePwd = (value: string): RegExpMatchArray | null => {
  return value.match(/^(?=.*\d)(?=.*[@#$!%&*?])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/);
};

/**
 * Validate string using regex.
 * Accept only aphabets.
 * @param value
 * @returns
 */
export const validateName = (value: string): RegExpMatchArray | null => {
  return value.match(/^[a-zA-Z]*$/);
}

/**
 * Validate string using regex.
 * Accept only numbers.
 * @param value
 * @returns
 */
export const validateNumber = (value: string): RegExpMatchArray | null => {
  return value.match(/^[0-9]+$/);
}

/**
 * This Email validation function is used in controled components.
 */
export const validateEmailControlled = (value: string): RegExpMatchArray | null => {
  return value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
}


export const validateDate = (value: string): RegExpMatchArray | null => {
  return value.match(/^((0?[1-9]|1[012])[/](0?[1-9]|[12][0-9]|3[01])[/](19|20)?[0-9]{2})*$/);
}

export type MobileObject = {
  mobileMaxLengthError: boolean;
  mobileMinLengthError: boolean;
  mobileValidError: boolean;
  mobileError: boolean;
  mobileNo: undefined | string;
};

/**
   * validate controlled component.
   * @param mobileNo
   * @returns
   */
export const validateMobileNumber = (mobileNo: string | undefined) => {

  if (mobileNo === '' || mobileNo === undefined) {
    return { mobileError: true };
  } else if (!validateNumber(getMobileNumberWithoutMinus(mobileNo))) {
    return { mobileValidError: true, mobileError: false };
  } else if (getMobileNumberWithoutMinus(mobileNo).length > 15) {
    return {
      mobileMaxLengthError: true,
      mobileValidError: false,
      mobileError: false,
    };
  } else if (getMobileNumberWithoutMinus(mobileNo).length < 10) {
    return {
      mobileMinLengthError: true,
      mobileValidError: false,
      mobileError: false,
    };
  } else if (getMobileNumberWithoutMinus(mobileNo).length === 10) {
    return {
      mobileMinLengthError: false,
      mobileValidError: false,
      mobileError: false,
      mobileMaxLengthError: false
    }
  } else {
    return {
      mobileMaxLengthError: false,
      mobileMinLengthError: false,
      mobileValidError: false,
      mobileError: false,
    };
  }
};

export const getMobileNumberWithoutMinus = (mobileNo: string): string => {
  const mobileNumberArr = mobileNo.split("-");
  let num = '';
  mobileNumberArr.forEach((val: string) => {
    num = num + val
  });
  return num
}

export const getErrorMassageForMobile = (mobileDetailsObj: MobileObject): string | undefined => {
  if (mobileDetailsObj.mobileError) {
    return messageObject.mobile;
  } else if (mobileDetailsObj.mobileValidError) {
    return messageObject.validMobile;
  } else if (mobileDetailsObj.mobileMaxLengthError) {
    return messageObject.mobileMaxLimit;
  } else if (mobileDetailsObj.mobileMinLengthError) {
    return messageObject.mobileMinLimit;
  }
};

export const setMobileFieldInErrorMode = (mobileDetailsObj: MobileObject): boolean => {
  return mobileDetailsObj.mobileError ||
    mobileDetailsObj.mobileValidError ||
    mobileDetailsObj.mobileMaxLengthError ||
    mobileDetailsObj.mobileMinLengthError
    ? true
    : false;
};

export const mobileNoIsNotValid = (
  mobileDetails: MobileObject
): boolean => {
  if (
    !mobileDetails.mobileError &&
    !mobileDetails.mobileValidError &&
    !mobileDetails.mobileMinLengthError &&
    !mobileDetails.mobileMaxLengthError &&
    mobileDetails.mobileNo !== undefined &&
    mobileDetails.mobileNo !== ''
  ) {
    return true;
  } else {
    return false;
  }
};


export function isEmpty(value): boolean {
  return (typeof value === "undefined" || value === null);
}


export function setTitle(titleName: string): void {
  document.title = titleName;
}

/**
 * Send last date
 * @param lastDate
 * @returns
 */
export const calculateTime = (lastDate: string | null | undefined): string => {
  const getTime = () => {
    const seconds = moment.utc().diff(moment.utc(lastDate!.substr(0, 23)), 'seconds');
    const minutes = moment.utc().diff(moment.utc(lastDate!.substr(0, 23)), 'minutes');
    const hours = moment.utc().diff(moment.utc(lastDate!.substr(0, 23)), 'hours');
    return hours < 1 ? (minutes < 1 ? seconds > 0 ? `${seconds} sec ago` : `0 sec ago` : `${minutes} min ago`) : `${hours} hrs ago`;
  };
  return !isEmpty(lastDate) ? getTime() : '';
};

/**
 * This function is convert number to phone number pattern
 * Example :- 1234567890 to 123-456-7890
 * @param phoneNumber
 * @returns
 */
export const phoneNumberPattern = (phoneNumber: string | null | undefined): string => {
  return !isEmpty(phoneNumber) ? phoneNumber!.replace(
    /^(\d{3})(\d{3})(\d{4,9}).*/,
    '$1-$2-$3'
  ) : ''
}

/**
 * Convert into min and sec.
 * @param time
 * @returns
 */
export const getSongDuration = (time: moment.DurationInputArg1): string => {
  const duration = moment.duration(time, 'seconds');
  const min = duration.minutes();
  const sec = duration.seconds();
  const durationTime = `${min < 10 ? '0' + min : min}:${sec < 10 ? '0' + sec : sec
    }`;

  return durationTime;
}


// ================================ Password and confirm password  =======================================
// ================================ All the Functions are reusable =======================================


export const isEmptyStr = (value: string): boolean => {
  return value === '' ? true : false;
};


interface IConfrimPasswordErr {
  isRequiredConfirmPassword: boolean;
  isValidconfirmPassword: boolean;
}

//This function is used for set confirm password error.
export const setConfirmPasswordError = (confirmPassword: string, pwdStateValue: IPasswordState): IConfrimPasswordErr => {
  if (isEmptyStr(confirmPassword)) {
    return {
      isRequiredConfirmPassword: true,
      isValidconfirmPassword: false,
    };
  } else if (
    pwdStateValue.confirmPassword !== '' &&
    pwdStateValue.confirmPassword !== pwdStateValue.password
  ) {
    return {
      isRequiredConfirmPassword: false,
      isValidconfirmPassword: true,
    };
  } else {
    return {
      isRequiredConfirmPassword: false,
      isValidconfirmPassword: false,
    };
  }
};


interface IPasswordErr {
  isRequired: boolean;
  isValid: boolean;
  passwordMinLengthError: boolean;
}

//This function is used for set confirm password error.
export const setPasswordError = (password: string): IPasswordErr => {
  if (isEmptyStr(password)) {
    return {
      isRequired: true,
      isValid: false,
      passwordMinLengthError: false,
    };
  } else if (password.length < 8) {
    return {
      isRequired: false,
      isValid: false,
      passwordMinLengthError: true,
    };
  } else if (!validatePwd(password)) {
    return {
      isRequired: false,
      isValid: true,
      passwordMinLengthError: false,
    };
  } else {
    return {
      isRequired: false,
      isValid: false,
      passwordMinLengthError: false,
    };
  }
};

export const setCnfrmFieldInErrorMode = (pwdStateValue: IPasswordState): boolean => {
  return pwdStateValue.isRequiredConfirmPassword ||
    pwdStateValue.isValidconfirmPassword
    ? true
    : false;
};

export const setPasswordFieldInErrorMode = (pwdStateValue: IPasswordState): boolean => {
  return pwdStateValue.isRequired ||
    pwdStateValue.isValid ||
    pwdStateValue.passwordMinLengthError
    ? true
    : false;
};

export interface IArtistDataObj {
  artistId: number;
  artistProfileUrl: string;
  artistUserId: number;
  artistUserRole: number;
  artistName: string;
}

/**
 * This is reusable block of code.
 * Inside this function gettng artist data from localstorage and return make object.
 * @param userRole
 * @param artistDataObj
 * @returns
 */
export const getArtistData = (userRole: 1 | 2 | 3) => {
  const artistDetails: any = {};
  const artistDataObj: IArtistDataObj | null = JSON.parse(localStorage.getItem('artistDetails')!);
  if (userRole === AdminRole && artistDataObj !== null) {
    artistDetails['artistId'] = artistDataObj.artistId;
    artistDetails['artistProfileUrl'] = artistDataObj.artistProfileUrl;
    artistDetails['artistUserId'] = artistDataObj.artistUserId;
    artistDetails['artistName'] = artistDataObj.artistName;
    artistDetails['artistUserRole'] = 3;
  }
  return artistDetails
}

//=================================================================\\

/**
 * This function is used for update redux state(Musicplayer state);
 * This function is reusable.
 * @param url
 * @param musicPlayerState
 * @param dispatch
 */
export const updateMusicPlayerReduxStoreWithNewData = (url: IUrlObj, musicPlayerState: IMusicState, dispatch: any): void => {
  const newUrlObjArr: IUrlObj[] = [];
  const newShuffleResetData: IUrlObj[] = [];
  if (localStorage.getItem('previewMode') === 'true') {
    localStorage.removeItem('previewMode');
    dispatch(musicPlayerActions.resetMusicPlayerPayload())
    musicPlayerState.musicPlayerPayload = null;
    musicPlayerState.suffleResetData = null;
  }
  if (
    !Array.isArray(musicPlayerState.musicPlayerPayload?.url) &&
    musicPlayerState.musicPlayerPayload !== null &&
    !Array.isArray(musicPlayerState.suffleResetData?.url) &&
    musicPlayerState.suffleResetData !== null
  ) {
    newUrlObjArr.push(
      ...[{ ...musicPlayerState.musicPlayerPayload?.url }, url]
    );
    newShuffleResetData.push(
      ...[{ ...musicPlayerState.suffleResetData?.url }, url]
    );
  } else if (Array.isArray(musicPlayerState.musicPlayerPayload?.url)
    && Array.isArray(musicPlayerState.suffleResetData?.url)) {
    newUrlObjArr.push(
      ...[...musicPlayerState.musicPlayerPayload!.url],
      url
    );
    newShuffleResetData.push(
      ...[...musicPlayerState.suffleResetData!.url],
      url
    );
  } else {
    newUrlObjArr.push(url);
    newShuffleResetData.push(url);
  }
  dispatch(
    musicPlayerActions.setMusicPayload({
      url: newUrlObjArr,
      onCloseHandler: {},
    })
  );
  dispatch(musicPlayerActions.setSuffledResetData({
    url: newShuffleResetData,
    onCloseHandler: {},
  }));
}



//========== Change Theme Reusable Function================\\

export const setTheme = (changeTheme: boolean) => {
  if (changeTheme) {
    document.body.classList.remove('light');
    document.body.classList.add('dark');
  } else {
    document.body.classList.remove('dark');
    document.body.classList.add('light');
  }
}

//============= scrollToSection Resusable Function =================================\\
export const scrollToSection = (sectionId: string, scroll: number): void => {
  const sectionEl = document.getElementById(`${sectionId}`);
  if (sectionEl) {
    window.scrollTo({ top: sectionEl.offsetTop - scroll, behavior: 'smooth' });
  }
}

export const setScrollTop = (): void => {
  window.scrollTo(0, 0);
}
