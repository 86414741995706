import React, { Suspense, useEffect } from 'react';
import { layoutActions } from './redux/actions/layout';
import { SnackbarProvider } from 'notistack';
import { useHistory } from 'react-router-dom';
import { RootState } from './redux/reducers';
import { useToasts } from 'react-toast-notifications';
import { createUploadLink } from 'apollo-upload-client';
import { ToastProvider } from 'react-toast-notifications';
import { ApolloError, useMutation } from '@apollo/client';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { artistAction } from './redux/actions/artistNotification';
import { subscriberAction } from './redux/actions/subscriberNotification';
import { BROWSERLAUNCH } from './subscribers/graphQl/mutations/browswerLaunch';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import { isAuthenticate, logOut, setTheme } from './services';
import { ApolloClient, ApolloLink, ApolloProvider, from, InMemoryCache } from '@apollo/client';
import { AdminRole, ArtistRole, BASE_URL, SubscriberRole, SUB_DIRCTORY } from './app-config';
import profilepic from './assets/images/artist/profile-d-pic.png';
import indexRoutes from './routes/indexRoutes';
import store from './redux/store';
import firebase from './firebase';
import ApiLoader from './loader';
import 'firebase/messaging';
import './App.scss';



const App = () => {
  const theme = localStorage.getItem('Theme');
  const history = useHistory();
  const location = useLocation();
  const { addToast } = useToasts();
  const layOutState = useSelector((state: RootState) => state.Layout);

  const queryParams = new URLSearchParams(location.search);
  const fbclid = queryParams.get('fbclid');
  const userId = +localStorage.getItem('id')!;
  const userRole = +localStorage.getItem('userRole')! as 1 | 2 | 3;

  const dispatch = useDispatch();
  if (theme === 'true' && layOutState.changeTheme === false) {
    dispatch(layoutActions.changeTheme(true));
  }

  setTheme(layOutState.changeTheme);

  // Redirect user after login according to user role.
  const redirectToHomePage = (userRole: 1 | 2 | 3): void => {
    const followerCount = localStorage.getItem('FollowersCount');
    switch (userRole) {
      case ArtistRole:
        history.push('/artist');
        break;
      case SubscriberRole:
        if (followerCount && +followerCount === 0) {
          history.push('/subscriber/search');
          console.log("followerCount", followerCount)
        } else {
          history.push('/subscriber')
          console.log("subscriber",)
        }
        break;
      case AdminRole:
        history.push('/admin');
        break;
    }
  };


  const [userRegister] = useMutation(BROWSERLAUNCH, {

    //Once completed the useMutation operation successfully then onComplted function execute with response data.
    onCompleted: (result) => {
      if (userRole === 2) {
        dispatch(subscriberAction.subscriberNotification(result ? +result.browserLaunch.unReadCount : 0)
        );
      } else if (userRole === 3) {
        dispatch(artistAction.artistNotification(result ? +result.browserLaunch.unReadCount : 0));
      }
    },

    //If any error occur during mutation then OnError function throw error.
    onError: (error: ApolloError) => {
      addToast(error.message, { appearance: 'error', autoDismiss: true });
    },
  });


  useEffect(() => {
    try {
      if (Notification.permission === 'granted' && firebase.messaging.isSupported()) {
        const messaging = firebase.messaging();

        messaging.onMessage((payload: any) => {
          if (userRole === 2) {
            dispatch(subscriberAction.subscriberNotification(+payload.data.badge));
          } else if (userRole === 3) {
            dispatch(artistAction.artistNotification(+payload.data.badge));
          }

        });
        messaging
          .requestPermission()
          .then(() => {
            return messaging.getToken();
          })
          .then((token) => {
            if (userId) {
              const postData = { browserDeviceToken: token, userId: userId, notificationType: userRole === SubscriberRole ? 2 : 1 };
              userRegister({ variables: { ...postData } });
            }
          });
      } else { console.log('====ERROR FROM ELSE PART FIREBASE ERROR====') }
    } catch (e) { console.log('====ERROR FROM APP.TSX FIREBASE ERROR') }
  }, []);


  useEffect(() => {
    if (localStorage.getItem('profilePicUrl')) {
      const profile = localStorage.getItem('profilePicUrl');
      localStorage.setItem('profilePicUrl', profile ? profile : '');
    } else {
      localStorage.setItem('profilePicUrl', profilepic)
    }
    const redirectRoute = history.location.pathname;
    const routeFromEmail: string | undefined = window.location.href;
    const forgetLink = routeFromEmail.includes('reset-password-step-2');
    const isfreeTier: undefined | string = window.location === undefined ? undefined : routeFromEmail.split('?')[1]?.split('&')[0];
    const artistExist: undefined | string = window.location === undefined ? undefined : routeFromEmail.split('?')[1]?.split('=')[0];

    if (!isAuthenticate() && redirectRoute !== '/admin-login' && isfreeTier !== undefined && isfreeTier !== 'IsFreeTierEnable=true' && artistExist !== undefined && artistExist !== 'songShareId' && !forgetLink) {
      if (!fbclid) {
        logOut();
        history.push('/landing-page');
        console.log('logout user')
      }
    } else {
      if (redirectRoute === '/' || redirectRoute === '/landing-page' || redirectRoute === '/admin-login') {
        console.log('redirectRoute')
        if (userRole !== null) {
          redirectToHomePage(userRole)
        }
      } else if (artistExist !== undefined && artistExist === 'songShareId' && !isAuthenticate()) {
        localStorage.setItem('userRole', '3');
        history.push('/login');
      }
    }
  }, [history]);


  return (
    <Switch>
      {indexRoutes.map((prop, key) => (
        <Route path={prop.path} component={prop.component} key={key} />
      ))}
    </Switch>
  );
};

const uploadLink = createUploadLink({ uri: BASE_URL });

// Setup the header for the request
const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token');
  const authorizationHeader = token ? `Bearer ${token}` : null;
  operation.setContext({
    headers: {
      authorization: authorizationHeader,
    },
  });
  return forward(operation);
});

const client = new ApolloClient({
  link: from([middlewareAuthLink, uploadLink]),
  cache: new InMemoryCache(),
});

const AppWrapper: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <SnackbarProvider maxSnack={3}>
          <ToastProvider placement={'bottom-right'}>
            <Router basename={SUB_DIRCTORY}>
              <Suspense fallback={<ApiLoader />}>
                <App />
              </Suspense>
            </Router>
          </ToastProvider>
        </SnackbarProvider>
      </Provider>
    </ApolloProvider >
  );
};

export default AppWrapper;
