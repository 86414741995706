import * as React from 'react';
import Loader from 'react-loader-spinner';
import './apiloader.css';

interface IProps {
  width?: number;
  height?: number;
}
export default class ApiLoader extends React.Component<IProps> {
  render(): JSX.Element {
    return (
      <div className={`popup`}>
        <Loader
          type="Audio"
          color="#6B3C98"
          height={this.props.height !== undefined ? this.props.height : 80}
          width={this.props.width !== undefined ? this.props.width : 100}
        />
      </div>
    );
  }
}
