import { UPDATE_HASNEXTPAGE, UPDATE_MYPLAYLISTDATA, UPDATE_PLAYLISTDATACOUNT } from "../../actions/myPlaylistData/action-names";
import { IMyPlayListInitialState } from "../../actions/myPlaylistData/action-types";

const initialState: IMyPlayListInitialState = {
    myPlayListDataArr: [],
    totalCount: 0,
    hasNextPage: true,
};

export default function myPlayListReducer(
    state: IMyPlayListInitialState = initialState,
    action: any
): IMyPlayListInitialState {
    switch (action.type) {
        case UPDATE_MYPLAYLISTDATA:
            return {
                ...state,
                myPlayListDataArr: action.payload
            };
        case UPDATE_PLAYLISTDATACOUNT:
            return {
                ...state,
                totalCount: action.payload
            };
        case UPDATE_HASNEXTPAGE:
            return {
                ...state,
                hasNextPage: action.payload
            };
        default:
            return { ...state };
    }
}