import { LOGOUT_ARTIST, LOGOUT_SUBSCRIBER } from "../../actions/logout/action-names";
import { ILogoutPayLoad } from "../../actions/logout/action-types";

 export const initialLogOutState: ILogoutPayLoad = {
    title: '',
    subTitle: '',
    isOpen: false,
    redirectRoute: '',
    history,
    params: {
        logout:false
    },
    onConfirm: () => {
        return;
    },
};

export default function logoutReducer(
    state: ILogoutPayLoad = initialLogOutState,
    action: any
): ILogoutPayLoad {
    switch (action.type) {
        case LOGOUT_ARTIST:
            return {
                ...state,
                ...action.payload,
            };
        case LOGOUT_SUBSCRIBER:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return { ...state };
    }
}
