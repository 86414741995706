import { SUBSCRIBER_NOTIFICATIONS } from "../../actions/subscriberNotification/action-names";
import { SubscriberNotificationType } from "../../actions/subscriberNotification/action-types";

interface NotiticationState {
  isLoading: boolean;
  count: number;
}

const initialState: NotiticationState = {
  isLoading: false,
  count: 0,
};

export default function notificationReducer(
  state: NotiticationState = initialState,
  action: any
): NotiticationState {
  const successAction = action as SubscriberNotificationType;

  switch (action.type) {
    case SUBSCRIBER_NOTIFICATIONS:
      return {
        ...state,
        count: successAction.subscriberNotification,
        isLoading: false,
      };
    default:
      return state;
  }
}